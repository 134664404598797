<template>
  <div class="wrap" v-loading="loading">
    <div class="title">
      打卡二维码
    </div>
    <div class="ewm_img">
      <img :src="QRcodeImg" alt="" />
    </div>
  </div>
</template>

<script>
import { punch_card_qr } from "@/api/tool/publish";
export default {
  data() {
    return {
      loading: false,
      // 二维码图片
      QRcodeImg: "",
    };
  },
  methods: {
    punch_card_qr() {
      punch_card_qr().then((res) => {
        if (!res.code) {
          this.QRcodeImg = res.data.punch_card_qr;
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.loading = true;
    this.punch_card_qr();
  },
};
</script>

<style  scoped>
.title{
  padding: 40px 40px 40px;
  font-size: 24px;
  text-align: center;
}
.ewm_img{
width: 400px;
height: 400px;
margin: 0 auto;
padding-bottom: 120px;
}
.ewm_img img{
  width: 100%;
  height: 100%;
  vertical-align: top;
}
</style>