import {
  require
} from './../../utils/http'

//发布
function getPublish(data) {

  return require('post', '/api/hr24_ee/tool/v1/jd_create/', data)
}

//更新
function getUpdate(data) {

  let obj = {
    jd_id: data.id,
    update_data: data.update_data
  }
  return require('post', '/api/hr24_ee/tool/v1/jd_update/', obj)
}

//显示隐藏
function gettrigger(data) {
  let obj = {
    jd_id: data.jd_id,
    jd_hide: data.jd_hide
  }
  return require('post', '/api/hr24_ee/tool/v1/jd_show_hide/', obj)
}
//删除
function delApi(data) {
  let obj = {
    jd_id: data.jd_id,

  }
  return require('post', '/api/hr24_ee/tool/v1/jd_delete/', obj)
}
//职位诱惑
function getwelfare() {

  return require('post', '/api/hr24_ee/tool/v1/jd_tags_back/')
}
//职位列表
function show_position_json(data = {}) {
  return require('get', '/api/public/v1/show_position_json/', data)
}

// 地点定位接口
function place_location(data = {}) {
  return require('post', '/api/hr24_ee/company/v1/site_location/', data)
}
// 获取节假日和城市分类
function odd_jd_data(data) {
  return require('get', '/api/hr24_ee/tool/v1/odd_jd_data/', data)
}

// 验证工作日期
function jd_workday(data) {
  return require('get', '/api/hr24_ee/tool/v1/jd_workday/', data)
}
// 获取职位创建时间
function jd_create_time(data) {
  return require('get', '/api/hr24_ee/tool/v1/jd_create_time/', data)
}



// 下订单
function wx_native_order(data) {
  return require('post', '/api/hr24_ee/tool/v1/wx_native_order/', data)
}
// PC 轮询 查询订单结果
function native_order_query(data) {
  return require('post', '/api/hr24_ee/liechang/v1/native_order_query/', data)
}

// 支付成功，发布零工需求
function jd_pay(data) {
  return require('post', '/api/hr24_ee/tool/v1/jd_pay/', data)
}



// 展示打卡二维码
function punch_card_qr(data) {
  return require('get', '/api/hrswork/v1/punch_card_qr/', data)
}


// 零工报名上岗列表
function odd_apply(data) {
  return require('get', '/api/hr24_ee/tool/v1/odd_apply/', data)  
}

// 零工企业发布已支付的需求
function odd_jd_list(data) {
  return require('get', '/apihr24_ee/tool/v1/odd_jd_list/', data)  
}




export {
  getPublish,
  getUpdate,
  gettrigger,
  getwelfare,
  delApi,
  show_position_json,
  place_location,
  odd_jd_data,
  jd_workday,
  jd_create_time,
  wx_native_order,
  native_order_query,
  jd_pay,
  punch_card_qr,
  odd_apply,
  odd_jd_list
}